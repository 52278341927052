import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/machines/smt/printer/juki-printer.png'
import ImageTwo from '../../../../data/images/bg/machines/smt/printer/pbt-printer.png'
import ImageThree from '../../../../data/images/bg/machines/smt/printer/manual-printer.png'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "АВТОМАТЫ",
            image: ImageOne,
            title: "SMART Принтеры паяльной пасты",
            desc: "Инновационное оборудование для нанесение паяльной пасты. Автоматический режим работы и отслеживание всех технологических параметров. Автоматическое совмещение трафарета и печатной платы с помощью СТЗ. Оптическая инспекция нанесения паяльной пасты на контактные площадки печатных плат. Применяется для серийного и крупносерийного производства поверхностного монтажа плат SMT, для работы в линии SMT и как отдельностоящее оборудование. Автоматические устройства для нанесения паяльной пасты требуют определенных условий эксплуатации и квалифицированного персонала.",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/автоматический-принтер-пасты",
            lang: "Подробно..."
        },
        {
            year: "ПОЛУ АВТ",
            image: ImageTwo,
            title: "Принтеры полу автоматические",
            desc: "Оборудование для поверхностного монтажа. Для нанесения паяльной пасты на печатные платы через трафарет в полу автоматическом режиме. Программирование технологических параметров: скорость движения ракелей, давление ракелей, скорость разделения и др. Автоматическое совмещение трафарета и платы. Применяется для мелкосерийного и серийного производства поверхностного монтажа плат (SMT) как отдельностоящее оборудование.",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/полуавтоматический-принтер",
            lang: "Подробно..."
        },
        {
            year: "РУЧНЫЕ",
            image: ImageThree,
            title: "Принтеры паяльной пасты начального уровня",
            desc: "Оборудование для поверхностного монтажа в условиях мелкосерийного производства. Для ручного и полуавтоматического нанесения паяльной пасты на печатные платы через трафарет. Применяется для небольших  производств  монтажа плат SMT и в условия серийного производства, как отдельностоящее оборудование. Принтеры  начального уровня не требуют особых условий эксплуатации и квалификации персонала.",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "АВТОМАТ",
            image: ImageOne,
            title: "SMART Принтери",
            desc: "Інноваційне обладнання для нанесення паяльної пасти. Автоматичний режим роботи і відстеження всіх технологічних параметрів. Автоматичне суміщення трафарету і друкованої плати за допомогою СТЗ. Оптична інспекція нанесення паяльної пасти на контактні площадки друкованих плат. Застосовується для серійного і багато серійного виробництва поверхневого монтажу плат SMT, для роботи в лінії SMT і як окреме обладнання. Автоматичні пристрої для нанесення паяльної пасти вимагають певних умов експлуатації і кваліфікованого персоналу.",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/автоматичний-принтер-пасти",
            lang: "Докладно..."
        },
        {
            year: "НАПІВ АВТ",
            image: ImageTwo,
            title: "Принтери напів-автоматичні",
            desc: "Обладнання для поверхневого монтажу. Для нанесення паяльної пасти на друковані плати через трафарет в напів-автоматичному режимі. Програмування технологічних параметрів: швидкість руху ракелів, тиск ракелів, швидкість розділення та ін. Автоматичне суміщення трафарету і плати. Застосовується для дрібносерійного і серійного виробництва поверхневого монтажу плат (SMT) як окреме обладнання.",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/напівавтоматичний-принтер",
            lang: "Докладно..."
        },
        {
            year: "РУЧНІ",
            image: ImageThree,
            title: "Принтери паяльної пасти початкового рівня",
            desc: "Обладнання для поверхневого монтажу в умовах дрібносерійного виробництва. Для ручного і напів-автоматичного нанесення паяльної пасти на друковані плати через трафарет. Застосовується для невеликих виробництв монтажу плат SMT і в умови серійного виробництва, як окреме обладнання. Принтери початкового рівня не вимагають особливих умов експлуатації та кваліфікації персоналу.",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні",
            lang: "Докладно..."
        }
    ]

    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Принтеры для нанесения пасты через трафарет применяются для группового нанесения паяльной пасты на контактные площадки печатных плат. Для мелкосерийного производства используют принтеры ручные универсальные (без натяжения в рамке) и с натяжением в рамке. Для серийного производства подходят полуавтоматические машины с электрическим или пневматическим приводом, отдельностоящие автоматы для работы вне линии и принтеры-автоматы в составе линии SMT.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Принтери для нанесення пасти через трафарет застосовуються для групового нанесення паяльної пасти на контактні площадки друкованих плат. Для дрібносерійного виробництва використовують принтери ручні універсальні (без натягу в рамці) і з натягом в рамці. Для серійного виробництва підходять напівавтоматичні машини з електричним або пневматичним приводом, окремі автомати для роботи поза лінією і принтери-автомати в складі лінії SMT.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
    
}

export default TimelineArea
